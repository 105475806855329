import { urlEndPoint } from '../Config';
import {
  callApi,
  callApiInit,
  callApiLogin,
  getTokenView,
  descryptedaes256cbc,
  callApiRegister,
  callApiRegister2,
  callSendFornData,
  callApi2,
} from './utils';

/**
 * The function `getToken` retrieves an access token from local storage, decrypts it if a certain
 * condition is met, and returns the access token.
 * @returns the `access_token` property of the `token` object.
 */
function getToken() {
  let token = '';
  try {

    const localToken = getTokenView(localStorage.getItem('session'));
    if (process.env.REACT_APP_AAA === 'true') {
      token = descryptedaes256cbc(localStorage.getItem('cea'), localToken);
    } else {
      token = JSON.parse(localStorage.getItem('cea'));
    }

    return token.access_token;
  } catch (error) {
    console.log("EREA", error);
  }
}

//VERIFY TOKEN
export const apiGetVerifyToken = async () => {
  const url = `${urlEndPoint}/api/v3/users/verify`;
  const json = await callApi(url, 'GET', getToken(), null);
  // console.log('json', json);
  return json;
};

//SESSION TOKEN
export const apiGetSession = async () => {
  const url = `${urlEndPoint}/api/v3/auth/init`;
  const json = await callApiInit(url, 'GET', null, null);
  return json;
};

/**
 * The function `apiVerifyTokenRecovery` sends a POST request to the specified URL with the provided
 * body and returns the response as JSON.
 * @param body - The `body` parameter is an object that contains the data to be sent in the request
 * body. It typically includes information required for token recovery, such as the user's email or
 * username.
 * @returns the JSON response from the API call.
 */
export const apiVerifyTokenRecovery = async (body) => {
  const url = `${urlEndPoint}/api/v3/auth/token/recovery`;
  const json = await callApi(url, 'POST', null, body);
  return json;
};

/**
 * The function `apiGetMe` makes a GET request to the `/api/v3/users/get/me` endpoint and returns the
 * response as JSON.
 * @returns The function `apiGetMe` is returning a JSON object.
 */

export const apiGetMe = async () => {
  const url = `${urlEndPoint}/api/v3/users/get/me`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};
/**
 * The function `apiUpdateMe` is an asynchronous function that updates the user's information by making
 * a PUT request to the specified API endpoint.
 * @param body - The `body` parameter is the data that you want to send in the request body. It can be
 * an object or a string that represents the data you want to update for the current user.
 * @returns the JSON response from the API call.
 */

export const apiUpdateMe = async (body) => {
  const url = `${urlEndPoint}/api/v3/users/me`;
  const json = await callApi(url, 'PUT', getToken(), body);
  return json;
};


export const apiUpdateUserData = async (body) => {
  const url = `${urlEndPoint}/api/v3/users/update/userBusiness`;
  const json = await callApi(url, 'PUT', getToken(), body);
  return json;
};

export const apiGetUserData = async () => {
  const url = `${urlEndPoint}/api/v3/users/get/userBusiness`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

/**
 * The function `apiUpdatePass` is an asynchronous function that updates a user's password using an API
 * call.
 * @param body - The `body` parameter is an object that contains the data to be sent in the request
 * body. It typically includes information required to update a user's password, such as the old
 * password and the new password.
 * @returns a JSON object.
 */
export const apiUpdatePass = async (body) => {
  const url = `${urlEndPoint}/api/v3/auth/token/pass`;
  const json = await callApi(url, 'PUT', getToken(), body);
  return json;
};

/**
 * The function `apiGetTokenPublic` sends a POST request to the specified URL with the provided user
 * data and returns the response as JSON.
 * @param dataUser - The `dataUser` parameter is an object that contains the user data needed to
 * authenticate and generate a token. It could include properties such as username, password, or any
 * other required information for authentication.
 * @returns a JSON object.
 */
export const apiGetTokenPublic = async (dataUser) => {
  const url = `${urlEndPoint}/api/v3/auth/token`;
  const body = dataUser;
  const json = await callApi(url, 'POST', null, body);
  return json;
};

/**
 * The function `apiGetMaintenceBookingID` makes a GET request to a specified URL with a client ID and
 * returns the JSON response.
 * @param id - The `id` parameter is the booking ID that is used to retrieve the client details for a
 * maintenance booking.
 * @returns a JSON object.
 */
export const apiGetMaintenceBookingID = async (id) => {
  const url = `${urlEndPoint}/api/v3/service/get/clientDetail/${id}/`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};
/**
 * The function `getGetMaintenceAdicional` makes a GET request to a specific endpoint to retrieve
 * additional maintenance information based on an ID.
 * @param id - The `id` parameter is the identifier of the maintenance adicional that you want to
 * retrieve. It is used to specify which adicional you want to get information about.
 * @returns a JSON object.
 */
export const getGetMaintenceAdicional = async (id) => {
  const url = `${urlEndPoint}/api/v3/maintenance/adicional/${id}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetAllStore = async () => {
  const url = `${urlEndPoint}/api/v3/store`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};
//LOGIN
export const apiLogin = async (requestData) => {
  const url = `${urlEndPoint}/api/v3/auth/signin`;
  const json = await callApiLogin(url, 'POST', null, requestData);
  return json;
};

export const getRecoveryPass = async (requestData) => {
  const url = `${urlEndPoint}/api/v3/auth/recovery`;
  const json = await callApi(url, 'POST', null, requestData);
  return json;
};

export const apiGetServiceList = async (body) => {
  const url = `${urlEndPoint}/api/v3/business/get/services-b2b`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};



export const apiGetServiceListPopular = async (body) => {
  const url = `${urlEndPoint}/api/v3/service/popular`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};



export const apiGetServicebBrand = async (value) => {
  const url = `${urlEndPoint}/api/v3/service/brand/${value}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetServiceAllBrand = async (value) => {
  const url = `${urlEndPoint}/api/v3/service/car/all/brand`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetServicebBrandModel = async (model) => {
  const url = `${urlEndPoint}/api/v3/service/model/${model}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetServiceDetail = async (idService) => {
  const url = `${urlEndPoint}/api/v3/service/detail/${idService}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetServiceTaskDetail = async (idService) => {
  const url = `${urlEndPoint}/api/v3/service/task/${idService}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetServiceLocationDetail = async (idService) => {
  const url = `${urlEndPoint}/api/v3/service/location/${idService}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetAllVehicleUser = async (idService) => {
  const url = `${urlEndPoint}/api/v3/business/get/vehicle`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};


export const apiGetNewToken = async (body) => {
  const url = `${urlEndPoint}/api/v3/business/get/new/token`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};


export const apiGetAllVehicleUserSearch = async (body) => {
  const url = `${urlEndPoint}/api/v3/business/search/vehicle`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};
export const apiGetAllRegion = async () => {
  const url = `${urlEndPoint}/api/v3/region`;
  const json =  await callApi2(url, 'GET', getToken(), null);
  return json;


};export const apiGetAllComune = async (isoCode) => {
  const url = `${urlEndPoint}/api/v3/region/comune/${isoCode}`;
  console.log(url);
  const json = await callApi2(url, 'GET', getToken(), null);
  return json;
};



export const apiGetAllTypeFuel = async (idService) => {
  const url = `${urlEndPoint}/api/v3/maintenance/fuel/all`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetDataPasarela = async (idPay) => {
  const url = `${urlEndPoint}/v1/kf/pay/${idPay}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiPutMaintenanceDetail = async (payload) => {
  const url = `${urlEndPoint}/v1/kf/put/detail`;
  const json = await callApi(url, 'PUT', getToken(), payload);
  return json;
};

export const apiGetSearchPlate = async (idService) => {
  const url = `${urlEndPoint}/api/v3/users/get/vehicle`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetHourDay = async (body) => {
  const url = `${urlEndPoint}/api/v3/maintenance/get/booking`;
  const json = await callApi(url, 'POST', getToken(), body);
  console.log(json);
  return json;
};

export const apiGetPlate = async (body) => {
  const url = `${urlEndPoint}/api/v3/users/get/plate`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const apiGetTaskMulti = async (body) => {
  const url = `${urlEndPoint}/api/v3/service/multitask`;
  const json = await callApi(url, 'POST', getToken(), body);
  console.log(json);
  return json;
};

export const apiGetPriceMulti = async (body) => {
  const url = `${urlEndPoint}/api/v3/maintenance/get/price`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const apiGetLogin = async (body) => {
  const url = `${urlEndPoint}/api/v3/auth/business/signin`;
  const json = await callApiLogin(url, 'POST', null, body);
  console.log(json)
  return json;
};


export const apiRegisterLogin = async (body) => {
  const url = `${urlEndPoint}/api/v3/auth/business/signup`;
  const json = await callApiRegister2(url, 'POST', null, body);

  return json;
};


export const apiSaveCard = async (body) => {
  const url = `${urlEndPoint}/api/v3/business/set/save/car`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const apiDelCard = async (body) => {
  const url = `${urlEndPoint}/api/v3/service/car/del`;

  const json = await callApi(url, 'DELETE', getToken(), body);

  return json;
};

export const apiGetAllMaintenance = async (year, month, limit) => {
  const url = `${urlEndPoint}/api/v3/maintenance/all/${year}/${month}/${limit}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const getCuponValidate = async (body) => {
  const url = `${urlEndPoint}/api/v3/service/cupon`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const createLinkAndOT = async (body) => {
  const url = `${urlEndPoint}/api/v3/maintenance`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const getStatusPay = async (idPay) => {
  const url = `${urlEndPoint}/v1/kf/status/${idPay}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const getServiceDetail = async (IdOT) => {
  const url = `${urlEndPoint}/v1/kf/get/detail/${IdOT}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const getCuponValidateTrx = async (body) => {
  const url = `${urlEndPoint}/v1/kf/cupon`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const apiGetAllTransactionLast = async () => {
  const url = urlEndPoint + '/api/v3/transaction/last';
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetReportStatUser = async () => {
  const url = `${urlEndPoint}/api/v3/report/stat/user/dashboard`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetCalendarAll = async (y, m) => {
  const url = `${urlEndPoint}/api/v3/business/get/calendar/${y}/${m}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetAllServiceUser = async (idService) => {
  const url = `${urlEndPoint}/api/v3/business/get/services-b2b`;
  console.log(url);
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};


export const apiGetStatServiceAdditionalVender = async (year) => {
  const url = urlEndPoint + '/api/v3/report/stat/service/additional/' + year;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetStatServiceCategoryRanking = async () => {
  const url = `${urlEndPoint}/api/v3/report/stat/category/service/ranking`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetStatServiceDashboard = async () => {
  const url = urlEndPoint + '/api/v3/report/stat/service/dashboard';
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetStatServiceMonthAmount = async () => {
  const url = urlEndPoint + '/api/v3/report/stat/service/month/amount';
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetStatServiceMonthCount = async () => {
  const url = urlEndPoint + '/api/v3/report/stat/service/month/count';
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetStatServiceRanking = async () => {
  const url = `${urlEndPoint}/api/v3/report/stat/service/ranking`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetStatServiceVender = async (year) => {
  const url = urlEndPoint + '/api/v3/report/stat/service/vender/' + year;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetStatGerence = async () => {
  const url = urlEndPoint + '/api/v3/report/stat/gerencia';
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetStatStoreRanking = async () => {
  const url = `${urlEndPoint}/api/v3/report/stat/store/ranking`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetStatVehicleRanking = async () => {
  const url = `${urlEndPoint}/api/v3/report/stat/vehicle/ranking`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetService = async (Status) => {
  const url = `${urlEndPoint}/api/v3/business/getAll/services/${Status}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetAllAdditional = async (storeId) => {
  const url = `${urlEndPoint}/api/v3/additional/getallAdditional/${storeId}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

/**
 * The function `AddReception` sends a POST request to register a reception using the provided
 * reception data.
 * @param receptionData - The `receptionData` parameter is an object that contains the data needed to
 * register a reception. It should include properties such as the receptionist's name, the date and
 * time of the reception, the purpose of the reception, and any other relevant information.
 * @returns the JSON response from the API call.
 */
export const AddReception = async (receptionData) => {
  const url = `${urlEndPoint}/api/v3/reception/register`;
  const json = await callApi(url, 'POST', getToken(), receptionData);
  return json;
};

/**
 * The function `apiGetAllPhotoReception` makes an API call to retrieve all images associated with a
 * maintenance ID.
 * @param maintenanceId - The `maintenanceId` parameter is the identifier for a specific maintenance
 * task or job. It is used to retrieve all the photo reception images associated with that maintenance
 * task.
 * @returns a JSON object.
 */
export const apiGetAllPhotoReception = async (maintenanceId) => {
  const url = `${urlEndPoint}/api/v3/reception/getAllImage/${maintenanceId}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

/**
 * The function `apiGetTotalServices` makes a GET request to the specified API endpoint and returns the
 * JSON response.
 * @returns a JSON object.
 */
export const apiGetTotalServices = async () => {
  const url = `${urlEndPoint}/api/v3/business/stat/service/dashboard`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

/**
 * The function `ApiChangeStatusMaintence` makes a POST request to a specified API endpoint to change
 * the status of a service.
 * @param body - The `body` parameter is an object that contains the data to be sent in the request
 * body. It is used to provide additional information or data to the server when making a request.
 * @returns a JSON object.
 */
export const ApiChangeStatusMaintence = async (body) => {
  const url = `${urlEndPoint}/api/v3/service/changeState`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

/**
 * The function `ApiUploadPhotoRecepcion` is an asynchronous function that uploads a photo to a
 * specific API endpoint and returns the response as JSON.
 * @param formData - The `formData` parameter is an object that contains the data to be sent in the
 * request body. It is typically used for sending files or binary data. In this case, it is being used
 * to send an image file for uploading.
 * @returns a JSON object.
 */
export const ApiUploadPhotoRecepcion = async (formData) => {
  const url = `${urlEndPoint}/api/v3/reception/uploadImage`;
  const json = await callSendFornData(url, getToken(), formData);
  return json;
};

/**
 * The function `ApiUploadPhotoAdditional` uploads a photo using a form data object.
 * @param formData - The `formData` parameter is an object that contains the data to be sent in the
 * request body. It is typically used for sending files or binary data. In this case, it is used to
 * send an image file to the server for uploading.
 * @returns a JSON object.
 */
export const ApiUploadPhotoAdditional = async (formData) => {
  const url = `${urlEndPoint}/api/v3/additional/uploadImage`;
  const json = await callSendFornData(url, getToken(), formData);
  return json;
};

/**
 * The function `apiCreateAdditional` sends a POST request to create additional data using an API.
 * @param body - The `body` parameter is the data that you want to send in the request body. It can be
 * an object or a string that represents the data you want to send to the server.
 * @returns a JSON object.
 */
export const apiCreateAdditional = async (body) => {
  const url = `${urlEndPoint}/api/v3/additional/create`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const apiEditAdditional = async (body) => {
  const url = `${urlEndPoint}/api/v3/additional/edit`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};



/**
 * The function `apiDelPhotoReception` sends a DELETE request to the specified URL with the provided
 * body and returns the response as JSON.
 * @param body - The `body` parameter is an object that contains the necessary data for the API
 * request. It could include information such as the ID of the photo to be deleted or any other
 * relevant data required by the API endpoint.
 * @returns a JSON object.
 */
export const apiDelPhotoReception = async (body) => {
  const url = `${urlEndPoint}/api/v3/reception/deleteImage`;
  const json = await callApi(url, 'DELETE', getToken(), body);
  return json;
};

/**
 * The function `apiGetAdditionalAll` makes an API call to retrieve additional information for a given
 * maintenance ID.
 * @param maintenanceId - The `maintenanceId` parameter is the identifier for a specific maintenance
 * record. It is used to retrieve additional information related to that maintenance record.
 * @returns a JSON object.
 */
export const apiGetAdditionalAll = async (maintenanceId) => {
  const url = `${urlEndPoint}/api/v3/additional/getAdditional/${maintenanceId}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

/**
 * The function `apiGetAdditionalImagen` makes an API call to retrieve additional images for a
 * maintenance additional item.
 * @param maintenanceAdditionalId - The `maintenanceAdditionalId` parameter is the ID of the
 * maintenance additional for which you want to retrieve additional images.
 * @returns a JSON object.
 */
export const apiGetAdditionalImagen = async (maintenanceAdditionalId) => {
  const url = `${urlEndPoint}/api/v3/additional/getAdditionalImages/${maintenanceAdditionalId}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

/**
 * The function `apiDelAdditional` sends a DELETE request to the specified URL with the provided body
 * and returns the response as JSON.
 * @param body - The `body` parameter is an object that contains the data to be sent in the request
 * body. It is used to provide additional information or data to the server when making a DELETE
 * request to the `/api/v3/additional/delAdditional` endpoint.
 * @returns a JSON object.
 */
export const apiDelAdditional = async (body) => {
  const url = `${urlEndPoint}/api/v3/additional/delAdditional`;
  const json = await callApi(url, 'DELETE', getToken(), body);
  return json;
};

/**
 * The function `apiSendApprovedAdditional` sends a POST request to the specified API endpoint with the
 * provided body and returns the response as JSON.
 * @param body - The `body` parameter is an object that contains the data to be sent in the request
 * body. It is used to provide additional information or data to the server when making the API call.
 * @returns the JSON response from the API call.
 */
export const apiSendApprovedAdditional = async (body) => {
  const url = `${urlEndPoint}/api/v3/additional/requestApproval`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const apiGetStatService = async (month, year) => {
  const url = `${urlEndPoint}/api/v3/report/stat/service/${year}/${month}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetStatAllDay = async () => {
  const url = `${urlEndPoint}/api/v3/report/stat/allday`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetStatServiceAll = async (year) => {
  const url = `${urlEndPoint}/api/v3/report/stat/serviceall/${year}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetStatBrandAll = async () => {
  const url = `${urlEndPoint}/api/v3/report/stat/brand`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetStatModelAll = async () => {
  const url = `${urlEndPoint}/api/v3/report/stat/model`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const ApiUploadPhotoOrder = async (formData) => {
  const url = `${urlEndPoint}/api/v3/order/uploadImage`;
  const json = await callSendFornData(url, getToken(), formData);
  return json;
};

export const apiDelPhotoOrder = async (body) => {
  const url = `${urlEndPoint}/api/v3/order/deleteImage`;
  const json = await callApi(url, 'DELETE', getToken(), body);
  return json;
};

export const apiGetAllPhotoOrder = async (maintenanceId) => {
  const url = `${urlEndPoint}/api/v3/order/getAllImage/${maintenanceId}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetOrderNext = async (body) => {
  const url = `${urlEndPoint}/api/v3/order/next`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const apiValidateText = async (body) => {
  const url = `${urlEndPoint}/api/v3/service/get/validate`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const apiSendValidateText = async (body) => {
  const url = `${urlEndPoint}/api/v3/service/set/sendmessage`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const apiGetAllMessage = async (body) => {
  const url = `${urlEndPoint}/api/v3/service/get/sendmessage`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};
export const apiGetScrapping = async (vehiculeID) => {
  const url = `${urlEndPoint}/api/v3/users/get/scrapping/plate/${vehiculeID}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetDataVehicule = async (vehiculeID) => {
  const url = `${urlEndPoint}/api/v3/users/get/data/${vehiculeID}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetDataHistoryVehicule = async (vehiculeID) => {
  const url = `${urlEndPoint}/api/v3/users/get/history/${vehiculeID}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetServiceByIdDetail = async (idService) => {
  const url = `${urlEndPoint}/api/v3/business/getById/services/${idService}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetTransferByIdDetail = async (idService) => {
  const url = `${urlEndPoint}/api/v3/business/getById/services/:id${idService}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const apiGetServiceListLink = async (body, zone, service) => {
  const url = `${urlEndPoint}/api/v3/service/zone/${zone}/${service}`;
  console.log(url);
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const apiCreateService = async (body) => {
  const url = `${urlEndPoint}/api/v3/maintenance/b2b`;
  console.log(body);
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const apiCreateTransfer = async (body) => {
  const url = `${urlEndPoint}/api/v3/transfer/b2b`;
  const json = await callApi(url, 'POST', getToken(), body);
  console.log(json);
  return json;
};
export const apiAddVehiclesBulk = async (body) => {
  const url = `${urlEndPoint}/api/v3/vehicles/bulk`;
  console.log(url);
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

